#debungcontrolls, .debugtimeline {
  width: 100%;
  box-sizing: border-box;
}

.rev_column, .rev_column .tp-parallax-wrap, .tp-svg-layer svg {
  vertical-align: top;
}

#debungcontrolls {
  z-index: 100000;
  height: auto;
  background: #0009;
  padding: 10px;
  position: fixed;
  bottom: 0;
}

.debugtimeline {
  height: 10px;
  white-space: nowrap;
  margin-bottom: 3px;
  display: none;
  position: relative;
}

.debugtimeline:hover {
  height: 15px;
}

.the_timeline_tester {
  height: 100%;
  width: 0;
  background: #e74c3c;
  position: absolute;
  top: 0;
  left: 0;
}

.rs-go-fullscreen {
  width: 100% !important;
  height: 100% !important;
  z-index: 9999999 !important;
  background: #fff !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
}

.debugtimeline.tl_slide .the_timeline_tester {
  background: #f39c12;
}

.debugtimeline.tl_frame .the_timeline_tester {
  background: #3498db;
}

.debugtimline_txt {
  color: #fff;
  white-space: nowrap;
  font-size: 7px;
  font-weight: 400;
  line-height: 10px;
  position: absolute;
  top: 0;
  left: 10px;
}

.rtl {
  direction: rtl;
}

@font-face {
  font-family: revicons;
  src: url("revicons.80a7204d.eot");
  src: url("revicons.80a7204d.eot#iefix") format("embedded-opentype"), url("revicons.93d74edb.woff") format("woff"), url("revicons.6716ab81.ttf") format("truetype"), url("revicons.40b40c51.svg#revicons") format("svg");
  font-weight: 400;
  font-style: normal;
}

[class*=" revicon-"]:before, [class^="revicon-"]:before {
  speak: none;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  margin-left: .2em;
  margin-right: .2em;
  font-family: revicons;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  display: inline-block;
}

.revicon-search-1:before {
  content: "";
}

.revicon-pencil-1:before {
  content: "";
}

.revicon-picture-1:before {
  content: "";
}

.revicon-cancel:before {
  content: "";
}

.revicon-info-circled:before {
  content: "";
}

.revicon-trash:before {
  content: "";
}

.revicon-left-dir:before {
  content: "";
}

.revicon-right-dir:before {
  content: "";
}

.revicon-down-open:before {
  content: "";
}

.revicon-left-open:before {
  content: "";
}

.revicon-right-open:before {
  content: "";
}

.revicon-angle-left:before {
  content: "";
}

.revicon-angle-right:before {
  content: "";
}

.revicon-left-big:before {
  content: "";
}

.revicon-right-big:before {
  content: "";
}

.revicon-magic:before {
  content: "";
}

.revicon-picture:before {
  content: "";
}

.revicon-export:before {
  content: "";
}

.revicon-cog:before {
  content: "";
}

.revicon-login:before {
  content: "";
}

.revicon-logout:before {
  content: "";
}

.revicon-video:before {
  content: "";
}

.revicon-arrow-combo:before {
  content: "";
}

.revicon-left-open-1:before {
  content: "";
}

.revicon-right-open-1:before {
  content: "";
}

.revicon-left-open-mini:before {
  content: "";
}

.revicon-right-open-mini:before {
  content: "";
}

.revicon-left-open-big:before {
  content: "";
}

.revicon-right-open-big:before {
  content: "";
}

.revicon-left:before {
  content: "";
}

.revicon-right:before {
  content: "";
}

.revicon-ccw:before {
  content: "";
}

.revicon-arrows-ccw:before {
  content: "";
}

.revicon-palette:before {
  content: "";
}

.revicon-list-add:before {
  content: "";
}

.revicon-doc:before {
  content: "";
}

.revicon-left-open-outline:before {
  content: "";
}

.revicon-left-open-2:before {
  content: "";
}

.revicon-right-open-outline:before {
  content: "";
}

.revicon-right-open-2:before {
  content: "";
}

.revicon-equalizer:before {
  content: "";
}

.revicon-layers-alt:before {
  content: "";
}

.revicon-popup:before {
  content: "";
}

.rev_slider_wrapper {
  z-index: 0;
  width: 100%;
  position: relative;
}

.rev_slider {
  position: relative;
  overflow: visible;
}

.entry-content .rev_slider a, .rev_slider a {
  box-shadow: none;
}

.tp-overflow-hidden {
  overflow: hidden !important;
}

.group_ov_hidden {
  overflow: hidden;
}

.rev_slider img, .tp-simpleresponsive img {
  border: none;
  margin: 0;
  padding: 0;
  transition: none;
  max-width: none !important;
}

.rev_slider .no-slides-text {
  text-align: center;
  padding-top: 80px;
  font-weight: 700;
}

.rev_slider > ul, .rev_slider > ul > li, .rev_slider > ul > li:before, .rev_slider_wrapper > ul, .tp-revslider-mainul > li, .tp-revslider-mainul > li:before, .tp-simpleresponsive > ul, .tp-simpleresponsive > ul > li, .tp-simpleresponsive > ul > li:before {
  text-indent: 0;
  background-image: none;
  background-position: 0 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
}

.rev_slider > ul > li, .rev_slider > ul > li:before, .tp-revslider-mainul > li, .tp-revslider-mainul > li:before, .tp-simpleresponsive > ul > li, .tp-simpleresponsive > ul > li:before {
  visibility: hidden;
}

.tp-revslider-mainul, .tp-revslider-slidesli {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
}

.fullscreen-container, .fullwidthbanner-container {
  padding: 0;
  position: relative;
}

.rev_slider li.tp-revslider-slidesli {
  position: absolute !important;
}

.tp-caption .rs-untoggled-content {
  display: block;
}

.tp-caption .rs-toggled-content {
  display: none;
}

.rs-toggle-content-active.tp-caption .rs-toggled-content {
  display: block;
}

.rs-toggle-content-active.tp-caption .rs-untoggled-content {
  display: none;
}

.rev_slider .caption, .rev_slider .tp-caption {
  visibility: hidden;
  white-space: nowrap;
  z-index: 1;
  display: block;
  position: relative;
  -webkit-font-smoothing: antialiased !important;
}

.rev_slider .caption, .rev_slider .tp-caption, .tp-simpleresponsive img {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
}

.rev_slider .tp-mask-wrap .tp-caption, .rev_slider .tp-mask-wrap :last-child, .wpb_text_column .rev_slider .tp-mask-wrap .tp-caption, .wpb_text_column .rev_slider .tp-mask-wrap :last-child {
  margin-bottom: 0;
}

.tp-svg-layer svg {
  width: 100%;
  height: 100%;
  position: relative;
}

.tp-carousel-wrapper {
  cursor: url("openhand.971173ed.cur"), move;
}

.tp-carousel-wrapper.dragged {
  cursor: url("closedhand.c7cc5026.cur"), move;
}

.tp_inner_padding {
  box-sizing: border-box;
  max-height: none !important;
}

.tp-caption.tp-layer-selectable {
  -webkit-user-select: all;
  -moz-user-select: all;
  -khtml-user-select: all;
  -o-user-select: all;
}

.tp-caption.tp-hidden-caption, .tp-forcenotvisible, .tp-hide-revslider, .tp-parallax-wrap.tp-hidden-caption {
  visibility: hidden !important;
  display: none !important;
}

.rev_slider audio, .rev_slider embed, .rev_slider iframe, .rev_slider object, .rev_slider video {
  max-width: none !important;
}

.tp-element-background {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.tp-blockmask, .tp-blockmask_in, .tp-blockmask_out {
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transform: scaleX(0)scaleY(0);
}

.tp-parallax-wrap {
  transform-style: preserve-3d;
}

.rev_row_zone {
  width: 100%;
  box-sizing: border-box;
  min-height: 50px;
  font-size: 0;
  position: absolute;
  left: 0;
}

.rev_column_inner, .rev_slider .tp-caption.rev_row {
  box-sizing: border-box;
  position: relative;
  width: 100% !important;
}

.rev_row_zone_top {
  top: 0;
}

.rev_row_zone_middle {
  top: 50%;
  transform: translateY(-50%);
}

.rev_row_zone_bottom {
  bottom: 0;
}

.rev_slider .tp-caption.rev_row {
  table-layout: fixed;
  vertical-align: top;
  font-size: 0;
  display: table;
  height: auto !important;
}

.rev_column {
  height: auto;
  box-sizing: border-box;
  font-size: 0;
  display: table-cell;
  position: relative;
}

.rev_column_inner {
  display: block;
  height: auto !important;
  white-space: normal !important;
}

.rev_column_bg {
  width: 100%;
  height: 100%;
  z-index: 0;
  box-sizing: border-box;
  background-clip: content-box;
  border: 0 solid #0000;
  position: absolute;
  top: 0;
  left: 0;
}

.tp-caption .backcorner, .tp-caption .backcornertop, .tp-caption .frontcorner, .tp-caption .frontcornertop {
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
}

.rev_column_inner .tp-loop-wrap, .rev_column_inner .tp-mask-wrap, .rev_column_inner .tp-parallax-wrap {
  text-align: inherit;
}

.rev_column_inner .tp-mask-wrap {
  display: inline-block;
}

.rev_column_inner .tp-parallax-wrap, .rev_column_inner .tp-parallax-wrap .tp-loop-wrap, .rev_column_inner .tp-parallax-wrap .tp-mask-wrap {
  line-height: 0;
  position: relative !important;
  top: auto !important;
  left: auto !important;
}

.tp-video-play-button, .tp-video-play-button i {
  vertical-align: top;
  text-align: center;
  line-height: 50px !important;
}

.rev_column_inner .rev_layer_in_column, .rev_column_inner .tp-parallax-wrap, .rev_column_inner .tp-parallax-wrap .tp-loop-wrap, .rev_column_inner .tp-parallax-wrap .tp-mask-wrap {
  vertical-align: top;
}

.rev_break_columns {
  display: block !important;
}

.rev_break_columns .tp-parallax-wrap.rev_column {
  width: 100% !important;
  display: block !important;
}

.fullwidthbanner-container {
  overflow: hidden;
}

.fullwidthbanner-container .fullwidthabanner {
  width: 100%;
  position: relative;
}

.tp-static-layers {
  z-index: 101;
  position: absolute;
  top: 0;
  left: 0;
}

.tp-caption .frontcorner {
  border-top: 40px solid #00a8ff;
  border-left: 40px solid #0000;
  border-right: 0 solid #0000;
  left: -40px;
}

.tp-caption .backcorner {
  border-bottom: 40px solid #00a8ff;
  border-left: 0 solid #0000;
  border-right: 40px solid #0000;
  right: 0;
}

.tp-caption .frontcornertop {
  border-bottom: 40px solid #00a8ff;
  border-left: 40px solid #0000;
  border-right: 0 solid #0000;
  left: -40px;
}

.tp-caption .backcornertop {
  border-top: 40px solid #00a8ff;
  border-left: 0 solid #0000;
  border-right: 40px solid #0000;
  right: 0;
}

.tp-layer-inner-rotation {
  position: relative !important;
}

img.tp-slider-alternative-image {
  width: 100%;
  height: auto;
}

.caption.fullscreenvideo, .rs-background-video-layer, .tp-caption.coverscreenvideo, .tp-caption.fullscreenvideo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.noFilterClass {
  filter: none !important;
}

.rs-background-video-layer {
  visibility: hidden;
  z-index: 0;
}

.caption.fullscreenvideo audio, .caption.fullscreenvideo iframe, .caption.fullscreenvideo video, .tp-caption.fullscreenvideo iframe, .tp-caption.fullscreenvideo iframe audio, .tp-caption.fullscreenvideo iframe video {
  display: none;
  width: 100% !important;
  height: 100% !important;
}

.fullcoveredvideo audio, .fullscreenvideo audio .fullcoveredvideo video, .fullscreenvideo video {
  background: #000;
}

.fullcoveredvideo .tp-poster {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  top: 0;
  left: 0;
}

.videoisplaying .html5vid .tp-poster {
  display: none;
}

.tp-video-play-button {
  color: #fff;
  cursor: pointer;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  z-index: 4;
  opacity: 0;
  background: #0000004d;
  border-radius: 5px;
  margin-top: -25px;
  margin-left: -25px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: opacity .3s ease-out !important;
}

.tp-audio-html5 .tp-video-play-button, .tp-hiddenaudio {
  display: none !important;
}

.tp-caption .html5vid {
  width: 100% !important;
  height: 100% !important;
}

.tp-video-play-button i {
  width: 50px;
  height: 50px;
  display: inline-block;
  font-size: 40px !important;
}

.rs-fullvideo-cover, .tp-dottedoverlay, .tp-shadowcover {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.tp-caption:hover .tp-video-play-button {
  opacity: 1;
  display: block;
}

.tp-caption .tp-revstop {
  vertical-align: top;
  display: none;
  border-left: 5px solid #fff !important;
  border-right: 5px solid #fff !important;
  margin-top: 15px !important;
  font-size: 25px !important;
  line-height: 20px !important;
}

.tp-seek-bar, .tp-video-button, .tp-volume-bar {
  cursor: pointer;
  outline: 0;
  margin: 0;
  line-height: 12px;
}

.videoisplaying .revicon-right-dir {
  display: none;
}

.videoisplaying .tp-revstop {
  display: inline-block;
}

.videoisplaying .tp-video-play-button {
  display: none;
}

.fullcoveredvideo .tp-video-play-button {
  display: none !important;
}

.fullscreenvideo .fullscreenvideo audio, .fullscreenvideo .fullscreenvideo video {
  object-fit: contain !important;
}

.fullscreenvideo .fullcoveredvideo audio, .fullscreenvideo .fullcoveredvideo video {
  object-fit: cover !important;
}

.tp-video-controls {
  opacity: 0;
  max-width: 100%;
  box-sizing: border-box;
  background-image: linear-gradient(#000 13%, #323232 100%);
  padding: 5px;
  transition: opacity .3s;
  display: table;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.rev-btn.rev-hiddenicon i, .rev-btn.rev-withicon i {
  font-size: 15px;
  transition: all .2s ease-out !important;
}

.tp-caption:hover .tp-video-controls {
  opacity: .9;
}

.tp-video-button {
  color: #fff;
  background: #00000080;
  border: 0;
  border-radius: 3px;
  padding: 0;
  font-size: 12px;
}

.tp-video-button:hover {
  cursor: pointer;
}

.tp-video-button-wrap, .tp-video-seek-bar-wrap, .tp-video-vol-bar-wrap {
  vertical-align: middle;
  padding: 0 5px;
  display: table-cell;
}

.tp-video-seek-bar-wrap {
  width: 80%;
}

.tp-video-vol-bar-wrap {
  width: 20%;
}

.tp-seek-bar, .tp-volume-bar {
  width: 100%;
  padding: 0;
}

.rs-fullvideo-cover {
  width: 100%;
  z-index: 5;
  background: none;
}

.disabled_lc .tp-video-play-button, .rs-background-video-layer audio::-webkit-media-controls, .rs-background-video-layer video::-webkit-media-controls, .rs-background-video-layer video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

.tp-audio-html5 .tp-video-controls {
  opacity: 1 !important;
  visibility: visible !important;
}

.tp-dottedoverlay {
  width: 100%;
  z-index: 3;
  background-repeat: repeat;
}

.tp-dottedoverlay.twoxtwo {
  background: url("gridtile.8744731e.png");
}

.tp-dottedoverlay.twoxtwowhite {
  background: url("gridtile_white.9cb21ec2.png");
}

.tp-dottedoverlay.threexthree {
  background: url("gridtile_3x3.9320e82e.png");
}

.tp-dottedoverlay.threexthreewhite {
  background: url("gridtile_3x3_white.6cb21726.png");
}

.tp-shadowcover {
  width: 100%;
  z-index: -1;
  background: #fff;
}

.tp-shadow1 {
  box-shadow: 0 10px 6px -6px #000c;
}

.tp-shadow2:after, .tp-shadow2:before, .tp-shadow3:before, .tp-shadow4:after {
  z-index: -2;
  content: "";
  width: 50%;
  max-width: 300px;
  background: none;
  position: absolute;
  top: 85%;
  bottom: 10px;
  left: 10px;
  transform: rotate(-3deg);
  box-shadow: 0 15px 10px #000c;
}

.tp-shadow2:after, .tp-shadow4:after {
  left: auto;
  right: 10px;
  transform: rotate(3deg);
}

.tp-shadow5 {
  position: relative;
  box-shadow: 0 1px 4px #0000004d, inset 0 0 40px #0000001a;
}

.tp-shadow5:after, .tp-shadow5:before {
  content: "";
  z-index: -2;
  border-radius: 100px / 20px;
  position: absolute;
  inset: 30% 20px 0;
  box-shadow: 0 0 25px #0009;
}

.tp-button {
  height: 30px;
  cursor: pointer;
  letter-spacing: -1px;
  border-radius: 3px;
  padding: 6px 13px 5px;
  font-family: arial, sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  color: #fff !important;
  text-shadow: 0 1px 1px #0009 !important;
  line-height: 45px !important;
}

.tp-button.big {
  color: #fff;
  text-shadow: 0 1px 1px #0009;
  padding: 9px 20px;
  font-size: 19px;
  font-weight: 700;
  line-height: 57px !important;
}

.purchase:hover, .tp-button.big:hover, .tp-button:hover {
  background-position: bottom, 15px 11px;
}

.purchase.green, .purchase:hover.green, .tp-button.green, .tp-button:hover.green {
  background-color: #21a117;
  box-shadow: 0 3px #104d0b;
}

.purchase.blue, .purchase:hover.blue, .tp-button.blue, .tp-button:hover.blue {
  background-color: #1d78cb;
  box-shadow: 0 3px #0f3e68;
}

.purchase.red, .purchase:hover.red, .tp-button.red, .tp-button:hover.red {
  background-color: #cb1d1d;
  box-shadow: 0 3px #7c1212;
}

.purchase.orange, .purchase:hover.orange, .tp-button.orange, .tp-button:hover.orange {
  background-color: #f70;
  box-shadow: 0 3px #a34c00;
}

.purchase.darkgrey, .purchase:hover.darkgrey, .tp-button.darkgrey, .tp-button.grey, .tp-button:hover.darkgrey, .tp-button:hover.grey {
  background-color: #555;
  box-shadow: 0 3px #222;
}

.purchase.lightgrey, .purchase:hover.lightgrey, .tp-button.lightgrey, .tp-button:hover.lightgrey {
  background-color: #888;
  box-shadow: 0 3px #555;
}

.rev-btn, .rev-btn:visited {
  box-sizing: border-box;
  cursor: pointer;
  padding: 12px 35px;
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 44px;
  box-shadow: none !important;
  outline: 0 !important;
  text-decoration: none !important;
}

.rev-btn.rev-uppercase, .rev-btn.rev-uppercase:visited {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 15px;
  font-weight: 900;
}

.rev-btn.rev-withicon i {
  font-weight: 400;
  position: relative;
  top: 0;
  margin-left: 10px !important;
}

.rev-btn.rev-hiddenicon i {
  opacity: 0;
  font-weight: 400;
  position: relative;
  top: 0;
  width: 0 !important;
  margin-left: 0 !important;
}

.rev-btn.rev-hiddenicon:hover i {
  opacity: 1 !important;
  width: auto !important;
  margin-left: 10px !important;
}

.rev-btn.rev-medium, .rev-btn.rev-medium:visited {
  padding: 10px 30px;
  font-size: 14px;
  line-height: 36px;
}

.rev-btn.rev-medium.rev-hiddenicon i, .rev-btn.rev-medium.rev-withicon i {
  font-size: 14px;
  top: 0;
}

.rev-btn.rev-small, .rev-btn.rev-small:visited {
  padding: 7px 20px;
  font-size: 12px;
  line-height: 28px;
}

.rev-btn.rev-small.rev-hiddenicon i, .rev-btn.rev-small.rev-withicon i {
  font-size: 12px;
  top: 0;
}

.rev-maxround {
  border-radius: 30px;
}

.rev-minround {
  border-radius: 3px;
}

.rev-burger {
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  border: 1px solid #33333340;
  border-radius: 50%;
  padding: 22px 0 0 14px;
  position: relative;
}

.rev-burger span {
  width: 30px;
  height: 3px;
  pointer-events: none;
  background: #333;
  transition: all .7s;
  display: block;
  transform-style: flat !important;
}

.rev-burger.revb-white span, .rev-burger.revb-whitenoborder span {
  background: #fff;
}

.rev-burger span:nth-child(2) {
  margin: 3px 0;
}

#dialog_addbutton .rev-burger:hover :first-child, .open .rev-burger :first-child, .open.rev-burger :first-child {
  transform: translateY(6px)rotate(-45deg);
}

#dialog_addbutton .rev-burger:hover :nth-child(2), .open .rev-burger :nth-child(2), .open.rev-burger :nth-child(2) {
  opacity: 0;
  transform: rotate(-45deg);
}

#dialog_addbutton .rev-burger:hover :last-child, .open .rev-burger :last-child, .open.rev-burger :last-child {
  transform: translateY(-6px)rotate(-135deg);
}

.rev-burger.revb-white {
  border: 2px solid #fff3;
}

.rev-burger.revb-darknoborder, .rev-burger.revb-whitenoborder {
  border: 0;
}

.rev-burger.revb-darknoborder span {
  background: #333;
}

.rev-burger.revb-whitefull {
  background: #fff;
  border: none;
}

.rev-burger.revb-whitefull span {
  background: #333;
}

.rev-burger.revb-darkfull {
  background: #333;
  border: none;
}

.rev-burger.revb-darkfull span, .rev-scroll-btn.revs-fullwhite {
  background: #fff;
}

@keyframes rev-ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }

  15% {
    opacity: 1;
    top: 50%;
  }

  50% {
    opacity: 0;
    top: 50%;
  }

  100% {
    opacity: 0;
    top: 29%;
  }
}

.rev-scroll-btn {
  text-align: center;
  cursor: pointer;
  width: 35px;
  height: 55px;
  box-sizing: border-box;
  border: 3px solid #fff;
  border-radius: 23px;
  display: inline-block;
  position: relative;
  left: 0;
  right: 0;
}

.rev-scroll-btn > * {
  color: #fff;
  letter-spacing: 2px;
  font-family: proxima-nova, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  display: inline-block;
}

.rev-scroll-btn > .active, .rev-scroll-btn > :focus, .rev-scroll-btn > :hover {
  color: #fff;
}

.rev-scroll-btn > .active, .rev-scroll-btn > :active, .rev-scroll-btn > :focus, .rev-scroll-btn > :hover {
  opacity: .8;
}

.rev-scroll-btn.revs-fullwhite span {
  background: #333;
}

.rev-scroll-btn.revs-fulldark {
  background: #333;
  border: none;
}

.rev-scroll-btn.revs-fulldark span, .tp-bullet {
  background: #fff;
}

.rev-scroll-btn span {
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  margin: -4px 0 0 -4px;
  animation: 2.5s linear infinite rev-ani-mouse;
  display: block;
  position: absolute;
  top: 29%;
  left: 50%;
}

.rev-scroll-btn.revs-dark {
  border-color: #333;
}

.rev-scroll-btn.revs-dark span {
  background: #333;
}

.rev-control-btn {
  z-index: 5;
  color: #fff;
  text-align: center;
  text-shadow: none;
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: #00000080;
  border-radius: 50px;
  font-family: Raleway;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.rev-cbutton-dark-sr, .rev-cbutton-light-sr {
  border-radius: 3px;
}

.rev-cbutton-light, .rev-cbutton-light-sr {
  color: #333;
  background-color: #ffffffbf;
}

.rev-sbutton {
  width: 37px;
  height: 37px;
  line-height: 37px;
}

.rev-sbutton-blue {
  background-color: #3b5998;
}

.rev-sbutton-lightblue {
  background-color: #00a0d1;
}

.rev-sbutton-red {
  background-color: #dd4b39;
}

.tp-bannertimer {
  visibility: hidden;
  width: 100%;
  height: 5px;
  z-index: 200;
  background: #00000026;
  position: absolute;
  top: 0;
}

.tp-bannertimer.tp-bottom {
  height: 5px;
  top: auto;
  bottom: 0 !important;
}

.tp-caption img {
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)";
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= #0ff, endColorstr= #0ff);
  zoom: 1;
  background: none;
}

.caption.slidelink a div, .tp-caption.slidelink a div {
  width: 3000px;
  height: 1500px;
  background: url("coloredbg.f12869f8.png");
}

.tp-caption.slidelink a span {
  background: url("coloredbg.f12869f8.png");
}

.tp-loader.spinner0, .tp-loader.spinner5 {
  background-image: url("loader.ae9c8f72.gif");
  background-repeat: no-repeat;
}

.tp-shape {
  width: 100%;
  height: 100%;
}

.tp-caption .rs-starring {
  display: inline-block;
}

.tp-caption .rs-starring .star-rating {
  float: none;
  vertical-align: top;
  display: inline-block;
  color: #ffc321 !important;
}

.tp-caption .rs-starring .star-rating, .tp-caption .rs-starring-page .star-rating {
  height: 1em;
  width: 5.4em;
  font-family: star;
  position: relative;
  font-size: 1em !important;
}

.tp-loader.spinner0, .tp-loader.spinner1 {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 3px;
  margin-top: -20px;
  margin-left: -20px;
  animation: 1.2s ease-in-out infinite tp-rotateplane;
  box-shadow: 0 0 20px #00000026;
}

.tp-caption .rs-starring .star-rating:before, .tp-caption .rs-starring-page .star-rating:before {
  content: "sssss";
  color: #e0dadf;
  float: left;
  position: absolute;
  top: 0;
  left: 0;
}

.tp-caption .rs-starring .star-rating span {
  float: left;
  padding-top: 1.5em;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  font-size: 1em !important;
}

.tp-caption .rs-starring .star-rating span:before {
  content: "SSSSS";
  position: absolute;
  top: 0;
  left: 0;
}

.tp-loader {
  z-index: 10000;
  position: absolute;
  top: 50%;
  left: 50%;
}

.tp-loader.spinner0 {
  background-position: center;
}

.tp-loader.spinner5 {
  width: 44px;
  height: 44px;
  background-color: #fff;
  background-position: 10px 10px;
  border-radius: 3px;
  margin: -22px;
}

@keyframes tp-rotateplane {
  0% {
    transform: perspective(120px)rotateX(0)rotateY(0);
  }

  50% {
    transform: perspective(120px)rotateX(-180.1deg)rotateY(0);
  }

  100% {
    transform: perspective(120px)rotateX(-180deg)rotateY(-179.9deg);
  }
}

.tp-loader.spinner2 {
  width: 40px;
  height: 40px;
  background-color: red;
  border-radius: 100%;
  margin-top: -20px;
  margin-left: -20px;
  animation: 1s ease-in-out infinite tp-scaleout;
  box-shadow: 0 0 20px #00000026;
}

@keyframes tp-scaleout {
  0% {
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.tp-loader.spinner3 {
  width: 70px;
  text-align: center;
  margin: -9px 0 0 -35px;
}

.tp-loader.spinner3 .bounce1, .tp-loader.spinner3 .bounce2, .tp-loader.spinner3 .bounce3 {
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 100%;
  animation: 1.4s ease-in-out infinite both tp-bouncedelay;
  display: inline-block;
  box-shadow: 0 0 20px #00000026;
}

.tp-loader.spinner3 .bounce1 {
  animation-delay: -.32s;
}

.tp-loader.spinner3 .bounce2 {
  animation-delay: -.16s;
}

@keyframes tp-bouncedelay {
  0%, 100%, 80% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.tp-loader.spinner4 {
  width: 40px;
  height: 40px;
  text-align: center;
  margin: -20px 0 0 -20px;
  animation: 2s linear infinite tp-rotate;
}

.tp-loader.spinner4 .dot1, .tp-loader.spinner4 .dot2 {
  width: 60%;
  height: 60%;
  background-color: #fff;
  border-radius: 100%;
  animation: 2s ease-in-out infinite tp-bounce;
  display: inline-block;
  position: absolute;
  top: 0;
  box-shadow: 0 0 20px #00000026;
}

.tp-loader.spinner4 .dot2 {
  animation-delay: -1s;
  top: auto;
  bottom: 0;
}

@keyframes tp-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes tp-bounce {
  0%, 100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

.tp-bullets.navbar, .tp-tabs.navbar, .tp-thumbs.navbar {
  min-height: 0;
  border: none;
  border-radius: 0;
  margin: 0;
}

.tp-bullets, .tp-tabs, .tp-thumbs {
  z-index: 1000;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.tp-tab, .tp-thumb {
  cursor: pointer;
  opacity: .5;
  box-sizing: border-box;
  position: absolute;
}

.tp-arr-imgholder, .tp-tab-image, .tp-thumb-image, .tp-videoposter {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.tp-tab.selected, .tp-tab:hover, .tp-thumb.selected, .tp-thumb:hover {
  opacity: 1;
}

.tp-tab-mask, .tp-thumb-mask {
  box-sizing: border-box !important;
}

.tp-tabs, .tp-thumbs {
  box-sizing: content-box !important;
}

.tp-bullet {
  width: 15px;
  height: 15px;
  cursor: pointer;
  background: #ffffff4d;
  position: absolute;
}

.tp-bullet.selected, .tp-bullet:hover {
  background: #fff;
}

.tparrows {
  cursor: pointer;
  width: 40px;
  height: 40px;
  z-index: 1000;
  background: #00000080;
  display: block;
  position: absolute;
}

.tparrows:hover {
  background: #000;
}

.tparrows:before {
  color: #fff;
  text-align: center;
  font-family: revicons;
  font-size: 15px;
  line-height: 40px;
  display: block;
}

.hginfo, .hglayerinfo {
  font-size: 12px;
  font-weight: 600;
}

.tparrows.tp-leftarrow:before {
  content: "";
}

.tparrows.tp-rightarrow:before {
  content: "";
}

body.rtl .tp-kbimg {
  left: 0 !important;
}

.dddwrappershadow {
  box-shadow: 0 45px 100px #0006;
}

.hglayerinfo {
  color: #fff;
  z-index: 2000;
  white-space: normal;
  background: #000000bf;
  padding: 5px 10px;
  line-height: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
}

.helpgrid, .hginfo {
  position: absolute;
}

.hginfo {
  color: #e74c3c;
  background: #000;
  padding: 2px 5px;
  top: -2px;
  left: -2px;
}

.indebugmode .tp-caption:hover {
  border: 1px dashed #c0392b !important;
}

.helpgrid {
  z-index: 0;
  border: 2px dashed #c0392b;
  top: 0;
  left: 0;
}

#revsliderlogloglog {
  color: #fff;
  width: 200px;
  height: 150px;
  z-index: 100000;
  background: #000000b3;
  padding: 15px;
  font-size: 10px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
}

.aden {
  filter: hue-rotate(-20deg) contrast(.9) saturate(.85) brightness(1.2);
}

.aden:after {
  mix-blend-mode: darken;
  background: linear-gradient(to right, #420a0e33, #0000);
}

.perpetua:after, .reyes:after {
  mix-blend-mode: soft-light;
  opacity: .5;
}

.inkwell {
  filter: sepia(.3) contrast(1.1) brightness(1.1) grayscale();
}

.perpetua:after {
  background: linear-gradient(#005b9a, #e6c13d);
}

.reyes {
  filter: sepia(.22) brightness(1.1) contrast(.85) saturate(.75);
}

.reyes:after {
  background: #efcdad;
}

.gingham {
  filter: brightness(1.05) hue-rotate(-10deg);
}

.gingham:after {
  mix-blend-mode: darken;
  background: linear-gradient(to right, #420a0e33, #0000);
}

.toaster {
  filter: contrast(1.5) brightness(.9);
}

.toaster:after {
  mix-blend-mode: screen;
  background: radial-gradient(circle, #804e0f, #3b003b);
}

.walden {
  filter: brightness(1.1) hue-rotate(-10deg) sepia(.3) saturate(1.6);
}

.walden:after {
  mix-blend-mode: screen;
  opacity: .3;
  background: #04c;
}

.hudson {
  filter: brightness(1.2) contrast(.9) saturate(1.1);
}

.hudson:after {
  mix-blend-mode: multiply;
  opacity: .5;
  background: radial-gradient(circle, #a6b1ff 50%, #342134);
}

.earlybird {
  filter: contrast(.9) sepia(.2);
}

.earlybird:after {
  mix-blend-mode: overlay;
  background: radial-gradient(circle, #d0ba8e 20%, #360309 85%, #1d0210 100%);
}

.mayfair {
  filter: contrast(1.1) saturate(1.1);
}

.mayfair:after {
  mix-blend-mode: overlay;
  opacity: .4;
  background: radial-gradient(circle at 40% 40%, #fffc, #ffc8c899, #111 60%);
}

.lofi {
  filter: saturate(1.1) contrast(1.5);
}

.lofi:after {
  mix-blend-mode: multiply;
  background: radial-gradient(circle, #0000 70%, #222 150%);
}

._1977 {
  filter: contrast(1.1) brightness(1.1) saturate(1.3);
}

._1977:after {
  mix-blend-mode: screen;
  background: #f36abc4d;
}

.brooklyn {
  filter: contrast(.9) brightness(1.1);
}

.brooklyn:after {
  mix-blend-mode: overlay;
  background: radial-gradient(circle, #a8dfc166 70%, #c4b7c8);
}

.xpro2 {
  filter: sepia(.3);
}

.xpro2:after {
  mix-blend-mode: color-burn;
  background: radial-gradient(circle, #e6e7e0 40%, #2b2aa199 110%);
}

.nashville {
  filter: sepia(.2) contrast(1.2) brightness(1.05) saturate(1.2);
}

.nashville:after {
  mix-blend-mode: lighten;
  background: #00469666;
}

.nashville:before {
  mix-blend-mode: darken;
  background: #f7b0998f;
}

.lark {
  filter: contrast(.9);
}

.lark:after {
  mix-blend-mode: darken;
  background: #f2f2f2cc;
}

.lark:before {
  mix-blend-mode: color-dodge;
  background: #22253f;
}

.moon {
  filter: grayscale() contrast(1.1) brightness(1.1);
}

.moon:before {
  mix-blend-mode: soft-light;
  background: #a0a0a0;
}

.moon:after {
  mix-blend-mode: lighten;
  background: #383838;
}

.clarendon {
  filter: contrast(1.2) saturate(1.35);
}

.clarendon:before {
  mix-blend-mode: overlay;
  background: #7fbbe333;
}

.willow {
  filter: grayscale(.5) contrast(.95) brightness(.9);
}

.willow:before {
  background-color: radial-gradient(40%, circle, #d4a9af 55%, #000 150%);
  mix-blend-mode: overlay;
}

.willow:after {
  mix-blend-mode: color;
  background-color: #d8cdcb;
}

.rise {
  filter: brightness(1.05) sepia(.2) contrast(.9) saturate(.9);
}

.rise:after {
  mix-blend-mode: overlay;
  opacity: .6;
  background: radial-gradient(circle, #e8c598cc, #0000 90%);
}

.rise:before {
  mix-blend-mode: multiply;
  background: radial-gradient(circle, #eccda926 55%, #321e0766);
}

._1977:after, ._1977:before, .aden:after, .aden:before, .brooklyn:after, .brooklyn:before, .clarendon:after, .clarendon:before, .earlybird:after, .earlybird:before, .gingham:after, .gingham:before, .hudson:after, .hudson:before, .inkwell:after, .inkwell:before, .lark:after, .lark:before, .lofi:after, .lofi:before, .mayfair:after, .mayfair:before, .moon:after, .moon:before, .nashville:after, .nashville:before, .perpetua:after, .perpetua:before, .reyes:after, .reyes:before, .rise:after, .rise:before, .slumber:after, .slumber:before, .toaster:after, .toaster:before, .walden:after, .walden:before, .willow:after, .willow:before, .xpro2:after, .xpro2:before {
  content: "";
  height: 100%;
  width: 100%;
  pointer-events: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

._1977, .aden, .brooklyn, .clarendon, .earlybird, .gingham, .hudson, .inkwell, .lark, .lofi, .mayfair, .moon, .nashville, .perpetua, .reyes, .rise, .slumber, .toaster, .walden, .willow, .xpro2 {
  position: relative;
}

._1977 img, .aden img, .brooklyn img, .clarendon img, .earlybird img, .gingham img, .hudson img, .inkwell img, .lark img, .lofi img, .mayfair img, .moon img, .nashville img, .perpetua img, .reyes img, .rise img, .slumber img, .toaster img, .walden img, .willow img, .xpro2 img {
  width: 100%;
  z-index: 1;
}

._1977:before, .aden:before, .brooklyn:before, .clarendon:before, .earlybird:before, .gingham:before, .hudson:before, .inkwell:before, .lark:before, .lofi:before, .mayfair:before, .moon:before, .nashville:before, .perpetua:before, .reyes:before, .rise:before, .slumber:before, .toaster:before, .walden:before, .willow:before, .xpro2:before {
  z-index: 2;
}

._1977:after, .aden:after, .brooklyn:after, .clarendon:after, .earlybird:after, .gingham:after, .hudson:after, .inkwell:after, .lark:after, .lofi:after, .mayfair:after, .moon:after, .nashville:after, .perpetua:after, .reyes:after, .rise:after, .slumber:after, .toaster:after, .walden:after, .willow:after, .xpro2:after {
  z-index: 3;
}

.slumber {
  filter: saturate(.66) brightness(1.05);
}

.slumber:after {
  mix-blend-mode: soft-light;
  background: #7d691880;
}

.slumber:before {
  mix-blend-mode: lighten;
  background: #45290c66;
}

.tp-kbimg-wrap:after, .tp-kbimg-wrap:before {
  height: 500%;
  width: 500%;
}

/*# sourceMappingURL=index.066abe67.css.map */
